<script>
	import Router from 'svelte-spa-router';
  	import { routes } from './routes/index';
	import { Modals, closeModal } from 'svelte-modals';
	import Tailwindcss from './assets/css/Tailwind.svelte';
	import Toast from "./toast/Toast.svelte";
	
/*
	let isLoading = false;

	// Subscribe to the loading store
	loading.subscribe(value => {
		isLoading = value;
	});
*/

</script>

<main class="overflow-hidden">
	<Toast/>
	<Tailwindcss />
	<Router {routes} />


</main>

<Modals>
	<div
		slot="backdrop"
		class="backdrop fixed inset-0 bg-black/50"
		on:click={closeModal}
	/>
</Modals>